"use client";

import { EmailLink } from "@/components/EmailLink/EmailLink.component";
import { useSite } from "@/contexts/site/site.context";
import { useUser } from "@/contexts/user/hooks/use-user.hook";
import { type ErrorMessageStatus, errorMessageStatusList } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import type { Locale } from "@/ts/locale/locale.types";
import { Alert } from "../Alert/Alert.component";
import { Link } from "../Link/Link.component";
import { Translation } from "../Translation/Translation.component";
type ErrorMessageProps = {
  readonly status: ErrorMessageStatus;
};
type ErrorMessageTranslationsProps = {
  readonly email: string;
  readonly manageAccount: string;
};
export const userNotFoundErrorMessage = {
  da: "Vi kunne ikke finde en bruger med denne e-mailadresse.",
  de: "Wir konnten keinen Benutzer mit dieser E-Mail-Adresse finden.",
  en: "We could not find a user with this email address.",
  no: "Vi kan ikke finne en bruker med denne e-postadressen",
  sv: "Vi kunde inte hitta en användare med denna e-postadress."
};
export const failedErrorMessage = {
  da: "Der skete en fejl. Prøv venligst igen senere.",
  de: "Ein Fehler ist aufgetreten. Probieren Sie Später nochmal.",
  en: "An error has occurred. Please try again later.",
  no: "Det skjedde en feil. Vennligst prøv igjen senere.",
  sv: "Ett fel har inträffat. Försök igen senare."
};
function getErrorMessageTranslations({
  email,
  manageAccount
}: ErrorMessageTranslationsProps): Record<ErrorMessageStatus, Record<Locale, JSX.Element | string>> {
  return {
    // Temporary error message for when the user tries to add a trial subscription
    ADD_TRIAL_INVALID_NAME: {
      da: inlineJsx`Vi mangler dit for- og efternavn for at kunne fortsætte. ${profileSettingsLink(manageAccount)} og prøv igen. Hvis det stadig ikke virker, så log ud og ind igen, eller vent et kvarter og prøv igen.`,
      de: inlineJsx`Wir benötigen Ihren Vor- und Nachnamen, um fortzufahren. ${profileSettingsLink(manageAccount)} und versuchen Sie es erneut. Wenn es immer noch nicht funktioniert, melden Sie sich ab und wieder an oder warten Sie eine Viertelstunde und versuchen Sie es erneut.`,
      en: inlineJsx`We need your first and last name to continue. ${profileSettingsLink(manageAccount)} and try again. If it still doesn't work, log out and back in or wait fifteen minutes and try again.`,
      no: inlineJsx`Vi trenger for- og etternavnet ditt for å fortsette. ${profileSettingsLink(manageAccount)} og prøv igjen. Hvis det fortsatt ikke fungerer, logg ut og inn igjen eller vent et kvarter og prøv igjen.`,
      sv: inlineJsx`Vi behöver ditt för- och efternamn för att kunna fortsätta. ${profileSettingsLink(manageAccount)} och försök igen. Om det fortfarande inte fungerar, logga ut och in igen eller vänta en kvart och försök igen.`
    },
    BLACKLISTED: {
      da: inlineJsx`Du kan ikke oprette en bruger med denne e-mailadresse. Kontakt os på ${supportEmailLink(email)}, hvis du har brug for hjælp.`,
      de: inlineJsx`Leider können Sie mit dieser E-Mail-Adresse keinen Benutzer erstellen. Kontaktieren Sie uns unter ${supportEmailLink(email)}, wenn Sie Hilfe benötigen.`,
      en: inlineJsx`You cannot create a user with this email address. Contact us at ${supportEmailLink(email)} if you need assistance.`,
      no: inlineJsx`Du kan ikke opprette en bruker med denne e-postadressen. Kontakt oss på ${supportEmailLink(email)} hvis du trenger hjelp.`,
      sv: inlineJsx`Du kan inte skapa en användare med denna e-postadress. Kontakta oss på ${supportEmailLink(email)} om du behöver hjälp.`
    },
    FAILED: failedErrorMessage,
    NOT_FOUND: userNotFoundErrorMessage,
    PASSWORD_REQUIREMENTS_NOT_MET: {
      da: "Dit indtastede kodeord opfylder ikke kravene. Dit kodeord skal være på mindst 8 tegn og indeholde et stort og et lille bogstav samt et tal.",
      de: "Ihr eingegebenes Passwort erfüllt nicht die Anforderungen. Ihr Passwort muss mindestens 8 Zeichen lang sein und einen Groß- und Kleinbuchstaben sowie eine Zahl enthalten.",
      en: "Your entered password does not meet the requirements. Your password must be at least 8 characters long and contain an uppercase and a lowercase letter as well as a number.",
      no: "Det inntastede passordet ditt oppfyller ikke kravene. Passordet ditt må være på minst 8 tegn og inneholde en stor og en liten bokstav samt et tall.",
      sv: "Ditt angivna lösenord uppfyller inte kraven. Ditt lösenord måste vara minst 8 tecken långt och innehålla en stor och en liten bokstav samt ett nummer."
    },
    TRIAL_ALREADY_USED: {
      da: "Du har allerede benyttet dig af muligheden for et personligt prøveabonnement. Du er velkommen til at kontakte vores salgsteam om et uforpligtende prøveabonnement til din arbejdsplads.",
      de: "Sie haben bereits die Möglichkeit eines persönlichen Probeabonnements genutzt. Gerne können Sie sich bezüglich des Erwerbs Ihres Abonnements oder eines unverbindlichen Unternehmens-Probeabonnement an unser Vertriebsteam wenden.",
      en: "You have already used the option of a personal trial subscription. You are welcome to contact our sales team about a non-binding trial subscription for your workplace.",
      no: "Du har allerede benyttet deg av muligheten for et personlig prøveabonnement. Men du er alltid velkommen til å kontakte vårt salgsteam for å få et uforpliktende prøveabonnement knyttet til din bedrift.",
      sv: "Du har redan utnyttjat möjligheten till en personlig provprenumeration. Du är välkommen att kontakta vårt försäljningsteam om en icke-bindande provprenumeration för din arbetsplats."
    },
    UNAUTHORIZED: {
      da: inlineJsx`Du har ikke adgang. Kontakt os på ${supportEmailLink(email)}, hvis du mener, at dette er en fejl.`,
      de: inlineJsx`Sie haben keinen Zugriff. Kontaktieren Sie uns unter ${supportEmailLink(email)}, wenn Sie glauben, dass dies ein Fehler ist.`,
      en: inlineJsx`You do not have access. Contact us at ${supportEmailLink(email)} if you believe this is an error.`,
      no: inlineJsx`Du har ikke tilgang. Kontakt oss på ${supportEmailLink(email)} hvis du mener dette er en feil.`,
      sv: inlineJsx`Du har inte åtkomst. Kontakta oss på ${supportEmailLink(email)} om du tror att detta är ett fel.`
    },
    USER_ALREADY_EXISTS: {
      da: "Du har allerede et login.",
      de: "Sie haben schon ein Login.",
      en: "You already have an account.",
      no: "Du har allerede en brukerkonto.",
      sv: "Du har redan ett konto."
    }
  };
}
export function ErrorMessage({
  status
}: ErrorMessageProps) {
  const {
    supportInformation: {
      email
    }
  } = useSite();
  const {
    urls: {
      manageAccount
    }
  } = useUser();
  const errorMessageTranslations = getErrorMessageTranslations({
    email,
    manageAccount
  });
  return <Alert data-sentry-element="Alert" data-sentry-component="ErrorMessage" data-sentry-source-file="ErrorMessage.component.tsx">
      <p data-testid="error-message">
        {errorMessageStatusList.includes(status) ? <Translation model={errorMessageTranslations[status]} /> : <Translation model={errorMessageTranslations.FAILED} />}
      </p>
    </Alert>;
}
function supportEmailLink(email: string) {
  return <EmailLink className="underline transition-colors hover:text-black" emailAddress={email} data-sentry-element="EmailLink" data-sentry-component="supportEmailLink" data-sentry-source-file="ErrorMessage.component.tsx" />;
}
function profileSettingsLink(manageAccount: string) {
  return <Link className="underline" href={manageAccount} data-sentry-element="Link" data-sentry-component="profileSettingsLink" data-sentry-source-file="ErrorMessage.component.tsx">
      <Translation model={{
      da: "Udfyld venligst dine oplysninger her",
      de: "Bitte füllen Sie Ihre Informationen hier aus",
      en: "Please fill out your information here",
      no: "Vennligst fyll ut informasjonen din her",
      sv: "Fyll i din information här"
    }} data-sentry-element="Translation" data-sentry-source-file="ErrorMessage.component.tsx" />
    </Link>;
}